"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Client library for Luncho API.
 * Use luncho.ts and luncho.py rather than LunchoAPI.ts and others.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const runtime = require("../runtime");
/**
 *
 */
class LunchoApi extends runtime.BaseAPI {
    /**
     * Returns A dict of LunchoDatas for supported countries. Data size is about 40KB. - **return**: dict[CountryCode, LunchoData] A dict of a country code and LunchoData.
     * All Luncho Data
     */
    allLunchoDataRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/v1/all-luncho-data`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * Returns A dict of LunchoDatas for supported countries. Data size is about 40KB. - **return**: dict[CountryCode, LunchoData] A dict of a country code and LunchoData.
     * All Luncho Data
     */
    allLunchoData() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.allLunchoDataRaw();
            return yield response.value();
        });
    }
    /**
     * Returns a dict of supported country codes and names so that you can show a dropdown list of countries. Data size is about 3.5KB.    E.g. {\'JP\': \'Japan\', \'US\': \'United States\'...}.     If data for a country is not available, either its ppp or exchange_rate is 0.    - **return**: dict[CountryCode, str] A dict of a country code and country name.
     * Countries
     */
    countriesRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/v1/countries`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * Returns a dict of supported country codes and names so that you can show a dropdown list of countries. Data size is about 3.5KB.    E.g. {\'JP\': \'Japan\', \'US\': \'United States\'...}.     If data for a country is not available, either its ppp or exchange_rate is 0.    - **return**: dict[CountryCode, str] A dict of a country code and country name.
     * Countries
     */
    countries() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.countriesRaw();
            return yield response.value();
        });
    }
    /**
     * Returns country code. This is available only when the server runs on Google App Engine. - **X_Appengine_Country**: Internal use. Ignore this. - **return**: str. A country code.
     * Country Code
     */
    countryCodeRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/v1/country-code`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Returns country code. This is available only when the server runs on Google App Engine. - **X_Appengine_Country**: Internal use. Ignore this. - **return**: str. A country code.
     * Country Code
     */
    countryCode() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.countryCodeRaw();
            return yield response.value();
        });
    }
    /**
     * Do nothing other than telling it\'s OK.
     * Health
     */
    healthRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/v1/health`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Do nothing other than telling it\'s OK.
     * Health
     */
    health() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.healthRaw();
            return yield response.value();
        });
    }
    /**
     * Returns LunchoData that is needed to convert between Luncho and local currency of the countryCode.   If data for the country is not available either ppp or exchange_rate is 0. Data size is about 400 bytes.  - **country_code**: client provided country code in ISO-3166-1-2 formant like \'JP\' - **return**: LunchoData
     * Luncho Data
     */
    lunchoDataRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.countryCode === null || requestParameters.countryCode === undefined) {
                throw new runtime.RequiredError('countryCode', 'Required parameter requestParameters.countryCode was null or undefined when calling lunchoData.');
            }
            const queryParameters = {};
            if (requestParameters.countryCode !== undefined) {
                queryParameters['country_code'] = requestParameters.countryCode;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/v1/luncho-data`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * Returns LunchoData that is needed to convert between Luncho and local currency of the countryCode.   If data for the country is not available either ppp or exchange_rate is 0. Data size is about 400 bytes.  - **country_code**: client provided country code in ISO-3166-1-2 formant like \'JP\' - **return**: LunchoData
     * Luncho Data
     */
    lunchoData(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.lunchoDataRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.LunchoApi = LunchoApi;
